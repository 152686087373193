import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    }
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册',
    }
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: '首页',
    }
  },
  {
    path: '/stars',
    name: 'stars',
    component: () => import('../views/StarsView.vue'),
    meta: {
      title: '信号方',
    }
  },
  {
    path: '/stars/personal',
    name: 'personal',
    component: () => import('../views/PersonalView.vue'),
    meta: {
      title: '信号空间',
    }
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('../views/ArticleView.vue'),
    meta: {
      title: '动态',
    }
  },
  {
    path: '/article/info',
    name: 'info',
    component: () => import('../views/ArticleInfoView.vue'),
    meta: {
      title: '动态',
    }
  },
  {
    path: '/plats',
    name: 'plats',
    component: () => import('../views/PlatformsView.vue'),
    meta: {
      title: '经纪商',
    }
  },
  {
    path: '/analy',
    name: 'analy',
    component: () => import('../views/AnalyView.vue'),
    meta: {
      title: '分析工具',
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: '关于',
    }
  },
  {
    path: '/about/partner',
    name: 'partner',
    component: () => import('../views/PartnerView.vue'),
    meta: {
      title: '代理申请',
    }
  },
  {
    path: '/about/us',
    name: 'aboutus',
    component: () => import('../views/AboutUsView.vue'),
    meta: {
      title: '关于我们',
    }
  },
  {
    path: '/about/help',
    name: 'help',
    component: () => import('../views/AboutHelpView.vue'),
    meta: {
      title: '帮助中心',
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - 易汇跟单社区";
    let nav_coll = document.getElementById("collapsibleNavId");
    if (nav_coll) {
      nav_coll.className = "collapse navbar-collapse";
    }
  }
  if (to.name == 'login') {
    window.open("/login")
  } else if(to.name == "register"){
    window.open("/login/register")
  } else {
    next()
  }
})

export default router
