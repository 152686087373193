import axios from "./http";

const api = {
    getLoginUser() {
        return axios.get("/api/values/GetLogin")
    },
    getBanners() {
        let dev = document.body.clientWidth < 768?'2':'1'
        return axios.get("/api/values/GetBanners/" + dev)
    },
    getTopSignals() {
        return axios.get("/api/values/GetTopSignals")
    },
    getPlatinfos() {
        return axios.get("/api/values/GetPlatinfos")
    },
    getNewsClass() {
        return axios.get("/api/article/GetClass")
    },
    getTopNews() {
        return axios.get("/api/article/get?code=c01")
    },
    getNews(obj) {
        return axios.get("/api/article/get",{ params: obj })
    },
    getNewsInfo(id) {
        return axios.get("/api/article/info/" + id)
    },
    getTopStars() {
        return axios.get("/api/stars/gettop")
    },
    getListStars(obj) {
        return axios.get("/api/stars/getlist", { params: obj })
    },
    getSignalView(id) {
        return axios.get("/api/stars/GetSignalView/" + id)
    },
    getOrders(obj) {
        return axios.get("/api/list/GetOrders/", { params: obj })
    },
    getHisOrders(obj) {
        return axios.get("/api/list/GetHisOrders/", { params: obj })
    },
    getFollower(id) {
        return axios.get("/api/stars/GetFollower/" + id)
    },
    getTableProfitMonthly(id, year) {
        return axios.get("/api/echarts/GetTableProfitMonthly/" + id+"?year="+year)
    },
    getProfitMonthly(id) {
        return axios.get("/api/echarts/GetMonthReport/" + id)
    },
    getLotsMonthly(id) {
        return axios.get("/api/charts/GetLotsMonthly/" + id)
    },
    getSymbol(id) {
        return axios.get("/api/echarts/GetSymbols/" + id)
    }
}

export default api;